import React from 'react';

const Feature = ({title, image, alt, link, body}) => {
    return (
        <div className="flex flex-col">
            <h3 className="text-2xl font-semibold text-gray-300 px-2 mb-4">{title}</h3>
            <img 
                src={image}
                alt={alt}
            />
            <div className="px-2 mt-4">
                <p className="text-white text-base">{body}</p>
                <a 
                    href={link}
                    className="underline text-secondary"
                >
                    Learn More
                </a>
            </div>
        </div>
    )
};

export default Feature;