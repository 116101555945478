import React from "react"

// Component imports
import Layout from "../components/Layout"
import Card from '../components/Card';
import Feature from '../components/Feature';
import SEO from '../components/SEO';

// Image imports
import headshot from '../images/mike-martin-headshot.jpg';
import estate from '../images/estate-photo.jpg';
import trusts from '../images/trust-photo.jpg';
import probate from '../images/probate-photo.jpg';
import business from '../images/business-photo.jpg';

// Routes for URLs
import * as ROUTES from '../constants/routes';


const headerImage = {
  width: "100%",
  minHeight: "600px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.60)), url(${headshot})`,
};

const Index = () => {
  return (
    <Layout>
      <SEO />
      <Card>
        <div className="grid grid-cols-1 sm:grid-cols-12 items-center sm:gap-x-4">
          <div className="sm:col-span-5">
            <div style={ headerImage } className="w-full h-full flex flex-col text-white justify-end">
              <div className="flex justify-center p-6">
                <h3 className="text-xl leading-tight">More Than 35 Years Of Successful Practice</h3>
              </div>
            </div>
          </div>
          <div className="sm:col-span-7 sm:px-12 mt-8 sm:mt-0">
            <h1 className="text-primary font-bold text-4xl mb-2 leading-tight">Knowledge. Experience. Determination.</h1>
            <h2 className="text-gray-800 font-semibold text-xl mb-4">When individuals and businesses need a seasoned attorney who can get results, they turn to Mike Martin.</h2>
            <p>Mike Martin is a Kansas City-based attorney with extensive experience in probate law, estate planning, trusts, and business law. Licensed in both Kansas and Missouri, Mike draws on more than 35 years of experience, and he prides himself on persistence that delivers results.</p>
            <p>He focuses on handling adversarial probate cases, and clients of all types benefit from his courtroom experience and confidence as a litigator.</p>
            <div className="w-full border border-gray-200 my-6"></div>
            <a 
              href="mailto:info@mikemartinlaw.com"
              className="bg-secondary text-white px-4 py-2 rounded text-uppercase text-xl"
            >
              Get in Touch
            </a>
          </div>
        </div>
      </Card>
      <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-4 sm:gap-y-0 sm:gap-x-8 mt-16">
        <Feature 
          title="Estates"
          image={estate}
          alt="Middle-aged couple"
          body="Estate planning is the process of arranging for the disposal of a person's property on death."
          link={ROUTES.ESTATES}
        />
        <Feature 
          title="Probate"
          image={probate}
          alt="Concerned looking woman"
          body="Probate is the process by which a deceased person's property, known as the 'estate,' is passed to his or her heirs and legatees (people named in the will)."
          link={ROUTES.PROBATE}
        />
        <Feature 
          title="Trusts"
          image={trusts}
          alt="Writing a will"
          body="A trust is recognized as a separate legal entity. A 'trustee' holds legal title to property for the benefit another person or group of people."
          link={ROUTES.TRUSTS}
        />
        <Feature 
          title="Business"
          image={business}
          alt="Business people"
          body="Estate planning is the process of arranging for the disposal of a person's property on death."
          link={ROUTES.BUSINESS}
        />
      </div>
    </Layout>
  )
}

export default Index;